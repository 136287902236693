import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'

import './controllers'

// Our packages
import './javascripts/user/turbolinks_prepare_for_cache'
import './javascripts/shared/map_init'

// Vendors
import smoothscroll from 'smoothscroll-polyfill'

import hljs from 'highlight.js/lib/core'

import javascript from 'highlight.js/lib/languages/javascript'
import html from 'highlight.js/lib/languages/xml'
import erb from 'highlight.js/lib/languages/erb'
import ruby from 'highlight.js/lib/languages/ruby'
import './javascripts/shared/turbo_confirm_modal'

import './javascripts/shared/youtube'

hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('html', html)
hljs.registerLanguage('erb', erb)
hljs.registerLanguage('ruby', ruby)

ActiveStorage.start()

// Requires
require('trix')
require('@rails/actiontext')

document.addEventListener('turbo:load', (event) => {
  hljs.highlightAll()
  smoothscroll.polyfill()
})
import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
